/* mixins e icone */
@import './styles/mixins';
@import './styles/icons';

:root {
  --color-primary: #cb8012;
  --color-primary-light: #dbdfee;
  --color-primary-mid: #7782a0;
  --color-primary-dark: #2e4691;
  --color-primary-button: #9cbde7;
  --color-primary-hover: #fbeaf0;
  --color-primary-low-opacity: #9cbde780;

  --color-secundary: #ffc653;
  --color-secundary-light: #fff1d0;
  --color-secundary-dark: #cb8012;
  --color-secundary: #848484;

  --color-white: #fff;
  --color-grey: #ccc;
  --color-black: #000;
  --color-error: #ff2340;

  --btn-primary-bg: #0d6efd;
  --btn-secondary-bg: #6c757d;

  --color-background: #f2f2f3;
  --color-title: #c06a2c;

  --color-orange-primary: var(--orange-400, #ef7f00);

  /* Scala grigi */
  --grey-50: #F1F2F4;
  --grey-100: #E3E6E8;
  --grey-200: #C6CCD2;
  --grey-300: #AAB3BB;
  --grey-400: #8E99A4;
  --grey-600: #5B6671;
  --grey-700: #444C55;
  --grey-800: #2D3339;
  --grey-900: #17191C;
}

html,
body {
  position: relative;
  color: #3f4254;
  background-color: var(--color-background);
  font-family: "Roboto", sans-serif;
  font-size: 16px;

  &.open-mobile-sidebar {
    overflow: hidden;
  }
}

.title-color {
  color: var(--color-title);
}

body {
  margin: 0;
  min-height: 100vh;

  &::-webkit-scrollbar {
    width: pxToRem(12);
    height: pxToRem(12);
  }

  &::-webkit-scrollbar-track {
    background: var(--grey-100, #E3E6E8);
    -webkit-border-radius: pxToRem(20);
    -moz-border-radius: pxToRem(20);
    border-radius: pxToRem(20);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--grey-200, #C6CCD2);
    -webkit-border-radius: pxToRem(20);
    -moz-border-radius: pxToRem(20);
    border-radius: pxToRem(20);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--grey-200, #C6CCD2);
  }
}

.button-principal {
  border-radius: 50px;
  background-color: #ef7f00;
  height: 2.5rem;
}

.bg-primary {
  background-color: var(--color-primary) !important;
}

.color-primary {
  color: var(--color-primary);
}

.btn-outline-primary {
  background-color: transparent;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
}

.btn-outline-primary.btn.disabled,
.btn-outline-primary.btn:disabled,
fieldset:disabled .btn {
  background-color: transparent;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
}

.btn-primary:hover {
  background-color: transparent !important;
  border: 1px solid var(--color-primary) !important;
  color: var(--color-primary) !important;
}

.btn-primary:disabled {
  background-color: var(--color-primary);
  border: 1px solid var(--color-primary);
  color: var(--color-white);
  opacity: 0.8;
}

.btn-primary {
  background-color: var(--color-primary);
  border: 1px solid var(--color-primary);
  color: var(--color-white);
}

.btn-underlined-primary {
  background-color: transparent;
  border: none;
  color: var(--color-primary);
  text-decoration: underline;
}

.btn-underlined-primary:hover {
  background-color: transparent;
  border: none;
  color: var(--color-primary);
  text-decoration: underline;
}

.btn-outline-primary:hover {
  background-color: var(--color-primary) !important;
  border: 1px solid var(--color-primary) !important;
  color: var(--color-white);
}

.btn-error {
  background-color: var(--color-error);
  border: 1px solid var(--color-error);
  color: var(--color-white);
}

.btn-error:hover {
  background-color: var(--color-white) !important;
  border: 1px solid var(--color-error) !important;
  color: var(--color-error) !important;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fs-10 {
  font-size: 0.625rem;
}

.fs-12 {
  font-size: 0.75rem;
}

.fs-14 {
  font-size: 0.875rem;
}

.fs16 {
  font-size: 1rem;
}

.fs-22 {
  font-size: 1.25rem;
}

.fs-26 {
  font-size: 1.625rem;
}

.fs-30 {
  font-size: 1.875rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
.mat-tab-label-content,
.mat-input-element,
.mat-sort-header-content,
.mat-step-text-label,
.mat-cell,
.mat-paginator-container,
select,
option,
input,
textarea,
button,
.btn,
th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell,
small {
  font-family: "Roboto", sans-serif !important;
}

h2 {
  @extend .fw-500;
}

h3 {
  @extend .fw-500;
  font-size: 2.3rem;
}

h4 {
  @extend .fw-700;
}

h5,
.ant-collapse-header {
  @extend .fw-600;
  font-size: 2.25rem;
}

h6 {
  @extend .fw-600;
  font-size: 1.8rem;
}

label,
button,
.btn {
  @extend .fw-600;
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active,
.btn:focus {
  border: none !important;
}

label {
  font-size: 0.9rem;
}

a {
  font-weight: 500;
  color: var(--color-primary);
  text-decoration: none;
}

a:hover {
  color: var(--color-primary);
}

.cursor-pointer {
  cursor: pointer;
}

.page {
  padding: 3rem 10rem;
}

.container-fluid {
  padding: 1rem 2rem;
}

.primary-container {
  background-color: white;
  padding: 1rem;
  border-radius: 0.25rem;
  box-shadow: 0px 0px 10px #00000029;
}

.mat-tab-body-content {
  padding: 1rem 0rem;
}

.form-control {
  padding: 0.4375rem 1rem;
  border-radius: 1.5rem;
  border: 1px solid #EBEBEB !important;

  &:read-only,
  &:disabled {
    background-color: #e9ecef;
  }
}

.form-select {
  &:disabled {
    background-color: #e9ecef;
  }
}

.me-4-5 {
  margin-right: 1.75rem;
}

.mat-select-disabled {
  background-color: #e9ecef !important;
}

.mat-tab-body-content {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

table .mat-table {
  width: 100%;
}

.mat-form-field {
  font-size: 14px;
  width: 100%;
}

table .mat-table td,
table .mat-table th {
  width: 25%;
}

thead,
tbody,
tfoot {
  width: 100%;
}

.margin-actions-button {
  margin-right: 0.625rem;
}

.mat-form-field {
  width: 30%;
}

th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type {
  padding-left: 0rem;
}

th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type {
  padding-top: 0rem;
}

.ant-menu-inline.ant-menu-root .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  padding: 0.5rem;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.ant-layout-sider,
.ant-layout-sider-trigger {
  background-color: var(--color-white);
  color: var(--color-black);
}

nz-header {
  padding: 0;
}

nz-content {
  margin: 0 1.5rem;
}

.h-input {
  height: 3.25rem;
}

[nz-icon] {
  font-size: 1.5rem !important;
}

button [nz-icon] {
  margin-right: 0.5rem;
}

.scrollable-y {
  overflow-y: auto;
}

.info-btn {
  text-align: center;
  vertical-align: middle;
  font-size: 0.5rem;
  background-color: transparent;
  border: none;
}

.info-btn [nz-icon] {
  font-size: 20px;
}

.form-check-input:checked {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

.form-check-input:checked:disabled {
  background-color: var(--color-disabled);
  border-color: var(--color-disabled);
  opacity: 1;
}

.ant-slider-track,
.ant-slider:hover .ant-slider-track {
  background-color: var(--color-primary);
}

.ant-slider-handle,
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open),
.ant-slider-handle:focus {
  border: 2px solid var(--color-primary);
}

.ant-slider-handle:focus {
  box-shadow: 0 0 0 5px rgb(214, 45, 102, 0.12);
}

.ant-input-group>.ant-input:first-child,
.ant-input-affix-wrapper {
  border-radius: 3.125rem;
  box-shadow: 0px 1px 4px 0px rgba(25, 33, 61, 0.08);
  height: 2.5rem !important;

  &:hover {
    border: 1px solid var(--color-primary) !important;
  }

  .ant-input-prefix {
    margin-right: 0 !important;
    padding: 0 0.5rem;
  }

  .anticon {
    &.anticon-search {
      font-size: 1.075rem !important;
    }
  }
}

.btn-danger {
  background-color: transparent;
  color: var(--bs-btn-bg);
  border: none;
}

.ant-collapse-ghost>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
  padding: 0rem;
}

.ant-menu-horizontal {
  border-bottom: none;
}

nz-alert {
  display: inline-block;
  border-radius: 4px;
}

.ant-alert-info {
  background-color: #eaecff;
  border-color: #eaecff;

  .ant-alert-message,
  .anticon {
    color: #2944fa;
  }
}

.ant-table-footer,
.ant-table-thead>tr>th {
  background: transparent;
  border-bottom: 1px solid var(--color-disabled);
}

.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table {
  border: none;
}

.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tbody>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>thead>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tbody>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>thead>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tbody>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tfoot>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tfoot>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>thead>tr>th,
.ant-table.ant-table-bordered>.ant-table-container {
  border-left: none;
  border-right: none;
}

.ant-switch-checked,
.ant-radio-inner::after {
  background: #EF7F00;
}

.ant-radio-checked .ant-radio-inner,
.ant-radio-input:focus+.ant-radio-inner,
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner {
  border-color: #EF7F00;
}

input,
textarea,
select,
.mat-select {
  border: 1px solid var(--color-disabled);
}

input:disabled,
.input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
textarea:disabled,
select:disabled,
.mat-select-disabled {
  border: 1px dotted var(--color-disabled);
}

.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-control,
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-select,
.input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
  border-color: var(--color-disabled);
}

.ant-btn {
  height: 2.5rem;
  padding: 0.5rem 1rem;
  border-radius: 3.125rem;
  max-height: 40px;
  width: 8rem !important;
  background-color: #EF7F00;
  border-color: #EF7F00;
  color: #ffff;
}

.ant-input {
  border-radius: 0.5rem;
  border: 1px solid #EBEBEB;

  &:focus,
  &:hover {
    border-color: #cb8012;
  }
}

.ant-picker {
  border-radius: 0.5rem;
}

.ant-steps-item-finish .ant-steps-item-icon {
  border-color: #cb8012;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
  background: #cb8012;
  border-color: #cb8012;
}

.anticon-check {
  color: #cb8012;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
  border-color: #cb8012;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after,
.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
  background-color: #EF7F00;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after,
.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-tail::after,
.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after,
.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-tail::after {
  background-color: #FFD89C;
}

.ant-steps-item-finish,
.ant-steps-item-process {
  .ant-steps-item-icon>.ant-steps-icon {
    .ant-steps-icon-dot {
      background: #EF7F00;
    }
  }
}

.ant-steps-item-wait {
  .ant-steps-item-icon>.ant-steps-icon {
    .ant-steps-icon-dot {
      background: #FFD89C;
    }
  }
}

.ant-radio-inner {
  border-color: #8E99A4;
  border-width: 2px;

  &::after {
    background-color: #EF7F00;
    border-color: #EF7F00;
  }
}

.ant-checkbox-input:focus+.ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: #EF7F00;
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: #EF7F00 !important;
    border-color: #EF7F00;
    border: 0;
  }

  &::after {
    border: 0;
  }
}

.ant-checkbox-inner {
  border: 2px solid #8E99A4;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
  background-color: #cb8012;
}

.ant-btn[nztype=default] {
  background-color: #fff;
  color: #EF7F00;
  width: 12rem !important;
  border: 1px solid #EF7F00;

  &:hover {
    background-color: #EF7F00;
    color: #fff;
  }

  &:active,
  &:focus {
    background-color: #fff;
    color: #EF7F00;
  }
}

.ant-btn:hover {
  border-color: #EF7F00;
  color: #EF7F00;
}

.ant-btn-primary:hover {
  background-color: #fff;
}

.ant-btn:active {
  border-color: #EF7F00;
  color: #fff;
  background-color: #EF7F00;
}

.ant-btn:focus {
  border-color: #EF7F00;
  color: #EF7F00;
}

.modal-button-cancel {
  width: 8rem !important;
  background-color: white !important;
  color: #EF7F00 !important;
  border: 1px solid #EF7F00 !important;
}

.back {
  width: 8rem !important;
  background-color: white !important;
  color: #EF7F00 !important;
  border: 1px solid #EF7F00 !important;
}

.ant-btn-primary:focus {
  background-color: #fff;
}

.card {
  --bs-card-bg: none;
}

.ant-select {
  .ant-select-arrow {
    color: var(--grey-900, #17191c) !important;
    font-weight: 300 !important;
    font-size: 2px !important;

    .anticon>svg {
      height: 0.75rem !important;
      vertical-align: middle !important;
    }
  }

  .ant-select-clear {
    span.ant-select-close-icon {
      margin-top: -0.5rem;
    }
  }

  &.ant-select-multiple {
    .ant-select-selector {
      min-height: 2.5rem !important;
    }

    .ant-select-selection-item {
      border-radius: 50px;
    }

    .ant-select-selection-item-remove>.anticon {
      line-height: 0;

      &>svg {
        width: 1rem;
        height: 1rem;
      }
    }
  }

  &.ant-select-single {
    .ant-select-selector {
      height: 2.5rem !important;

      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        line-height: pxToRem(41) !important;
      }
    }

    &:not(.ant-select-customize-input) {
      .ant-select-selector .ant-select-selection-search-input {
        height: 2.5rem !important;
      }
    }
  }

  &:not(.ant-select-customize-input) {
    .ant-select-selector {
      border: 1px solid #EBEBEB !important;
      border-radius: 50px !important;
      background-color: transparent !important;
      color: var(--grey-900, #17191c) !important;

      &:hover {
        border: 1px solid #cb8012 !important;
      }
    }
  }
}

.alt-black-border {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid var(--grey-900, #17191c) !important;
    border-radius: 50px !important;
    background-color: transparent !important;
    color: var(--grey-900, #17191c) !important;
  }
}


.header-widget {
  color: var(--grey-900, #17191c);
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0px;
}

.text-mini-widget {
  color: var(--grey-900, #17191c);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.data-mini-widget {
  color: #17191c;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media only screen and (max-width: 1467px) {
  .data-mini-widget {
    color: #17191c;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.border-mini-widget {
  border-radius: 1.5rem;
  box-shadow: 0px 1px 30px 2px rgba(16, 24, 40, 0.07);
}

/* Modali */
.ant-modal-wrap {
  &::-webkit-scrollbar {
    width: pxToRem(12);
    height: pxToRem(12);
  }

  &::-webkit-scrollbar-track {
    background: var(--grey-100, #E3E6E8);
    -webkit-border-radius: pxToRem(20);
    -moz-border-radius: pxToRem(20);
    border-radius: pxToRem(20);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--grey-200, #C6CCD2);
    -webkit-border-radius: pxToRem(20);
    -moz-border-radius: pxToRem(20);
    border-radius: pxToRem(20);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--grey-200, #C6CCD2);
  }

  .ant-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    top: 0;
    overflow: hidden;

    .ant-modal-content {
      flex: auto;
      padding: 1.5rem 1.81rem;
      border-radius: 1rem !important;

      .ant-modal-header {
        padding: 0 0 2.31rem !important;
        border: 0;

        .ant-modal-title {
          color: var(--grey-900, #17191C);
          font-family: Roboto, sans-serif !important;
          font-size: 2rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;

          /* Modalità mobile/tablet */
          @media (max-width: 992px) {
            font-size: 1.5rem;
            text-align: center;
          }
        }
      }

      .ant-modal-body {
        padding: 0 !important;
      }

      .ant-modal-footer {
        padding: 0 !important;
        border: 0;
        display: flex;
        flex-direction: row;
        gap: 1rem;

        /* Modalità mobile/tablet */
        @media (max-width: 992px) {
          flex-direction: column;
        }

        button {
          &.ant-btn {
            flex: 1;
            padding: 0.5rem 1rem;
            font-family: Roboto, sans-serif !important;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 500;
            line-height: 117.41%;

            /* Modalità mobile/tablet */
            @media (max-width: 992px) {
              width: 100% !important;
            }

            &:not(.ant-btn-primary) {
              background-color: white !important;
              color: var(--orange-400, #EF7F00) !important;
              border: 1px solid #ef7f00 !important;

              &:hover {
                background-color: #ef7f00 !important;
                color: white !important;
              }
            }

            &.ant-btn-primary {
              margin-left: 0 !important;
            }
          }
        }
      }
    }
  }
}

/* Popover */
.ant-popover {
  .ant-popover-content {
    .ant-popover-inner {
      border-radius: 1.5rem;
      background: #FFF;
      box-shadow: 0 1px 30px 2px rgba(16, 24, 40, 0.07);

      .ant-popover-inner-content {
        padding: 1.0625rem 1.4375rem;
      }
    }
  }
}

/* Tabs */
.ant-tabs {
  gap: 2rem;

  /* Modalità mobile/tablet */
  @media (max-width: 992px) {
    gap: 1.5rem;
  }

  .ant-tabs-nav {
    margin-bottom: 0;

    .ant-tabs-nav-wrap {
      border-bottom: 1px solid #C6CCD2;

      /* Modalità mobile/tablet */
      @media (max-width: 992px) {
        border-bottom: 0;
      }

      .ant-tabs-nav-list {
        gap: 2.44rem;

        /* Modalità mobile/tablet */
        @media (max-width: 992px) {
          gap: 1.5rem;
        }

        .ant-tabs-tab {
          padding: 0.5rem 0;
          margin: 0;
          font-family: Roboto, sans-serif;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;
          line-height: 117.41%;

          .ant-tabs-tab-btn {
            color: var(--grey-600, #5B6671);
          }

          &.ant-tabs-tab-active {
            .ant-tabs-tab-btn {
              color: var(--orange-400, #EF7F00);
            }
          }
        }

        .ant-tabs-ink-bar {
          background: var(--orange-400, #EF7F00);
        }
      }
    }
  }
}