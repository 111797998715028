.icon-custom {
  &.menu {
    &::before {
      content: url('../assets/icon/menu.svg');
    }
  }

  &.arrow-left-orange {
    &::before {
      content: url('../assets/icon/arrow-left-orange.svg');
    }
  }

  &.settings-orange {
    &::before {
      content: url('../assets/icon/impostazioni-orange.svg');
    }
  }

  &.arrow-left-green {
    &::before {
      content: url('../assets/icon/arrow-left-green.svg');
    }
  }

  &.arrow-left-ios {
    &::before {
      content: url('../assets/icon/arrow-left-ios.svg');
    }
  }

  &.arrow-right-ios {
    &::before {
      content: url('../assets/icon/arrow-right-ios.svg');
    }
  }

  &.calendar-orange {
    &::before {
      content: url('../assets/icon/calendar-orange.svg');
    }
  }

  &.pencil-white {
    &::before {
      content: url('../assets/icon/pencil-white.svg');
    }
  }
}